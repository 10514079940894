// <<<<<   chat application shots import starts   >>>>>
import loginPage from "assets/images/screenshots/project/chat_application/login/login.png";
// import loginInvalidEmailError from 'assets/images/screenshots/project/chat_application/login/login-error-invalid-email.png'
import loginEmailPasswordError from "assets/images/screenshots/project/chat_application/login/login-email-password-wrong.png";

import registerPage from "assets/images/screenshots/project/chat_application/register/register.png";
import registerInvalidEmail from "assets/images/screenshots/project/chat_application/register/register-email-invalid.png";
import registerEmailAlreadyUsed from "assets/images/screenshots/project/chat_application/register/register-already-used-email.png";
import registerPasswordConfirmPasswordNotMatch from "assets/images/screenshots/project/chat_application/register/register-password-confirm-password-not-match.png";

import imageSelecting from "./images/screenshots/project/chat_application/file_sharing/selecting-image-before-sending.png";
import imageSelected from "./images/screenshots/project/chat_application/file_sharing/file-img-selected.png";
import imageSent from "./images/screenshots/project/chat_application/file_sharing/img-sent.png";

import realTimeMessaging1 from "./images/screenshots/project/chat_application/real_time_message/real-time-messaging-1.png";
import realTimeMessaging2 from "./images/screenshots/project/chat_application/real_time_message/real-time-messaging-2.png";
import realTimeMessaging3 from "./images/screenshots/project/chat_application/real_time_message/real-time-messaging-3.png";

import videoCallShot1 from "./images/screenshots/project/chat_application/calls/video_call/video_call_shot1.jpg";
import videoCallShot2 from "./images/screenshots/project/chat_application/calls/video_call/video_call_shot2.jpg";
import videoCallShot3 from "./images/screenshots/project/chat_application/calls/video_call/video_call_shot3.jpg";

import voiceCallShot1 from "./images/screenshots/project/chat_application/calls/voice_call/voice_call_shot1.jpg";
import voiceCallShot2 from "./images/screenshots/project/chat_application/calls/voice_call/voice_call_shot2.jpg";
import voiceCallShot3 from "./images/screenshots/project/chat_application/calls/voice_call/voice_call_shot3.jpg";

import fileSelecting from "./images/screenshots/project/chat_application/file_sharing/selecting-files-before-sending.png";
import fileSelected from "./images/screenshots/project/chat_application/file_sharing/files-selected.png";
import fileSent from "./images/screenshots/project/chat_application/file_sharing/file-sent.png";

import recordingStarted from "./images/screenshots/project/chat_application/real_time_message/recording-started.png";
import recordingSaved from "./images/screenshots/project/chat_application/real_time_message/recording-saved.png";
import recordingSent from "./images/screenshots/project/chat_application/real_time_message/record-sent.png";
import recordingPlay from "./images/screenshots/project/chat_application/real_time_message/record-play.png";

import onlineStatus1 from "./images/screenshots/project/chat_application/online_status/online-status-2.png";

import pagination1 from "./images/screenshots/project/chat_application/pagination_infinite_scroll/pagination-1.png";

import homeMobile from "./images/screenshots/project/chat_application/responsive_designs/home-mobile.png";
import homeTab from "./images/screenshots/project/chat_application/responsive_designs/home-tablet.png";
import chatPageMobile from "./images/screenshots/project/chat_application/responsive_designs/chat-page-mobile.png";
import chatPageTab from "./images/screenshots/project/chat_application/responsive_designs/chat-page-tablet.png";
import profilePageDesktop from "./images/screenshots/project/chat_application/responsive_designs/profile-page-desktop.png";
import profilePageMobile1 from "./images/screenshots/project/chat_application/responsive_designs/profile-page-mobile-1.png";
import profilePageMobile2 from "./images/screenshots/project/chat_application/responsive_designs/profile-page-mobile-2.png";
// <<<<<   chat application shots import ends   >>>>>

// <<<<<   e-commerce shots import starts   >>>>>
import home1 from "./images/screenshots/project/e_commerce/home/home_1.png";
import home2 from "./images/screenshots/project/e_commerce/home/home_2.png";
import home3 from "./images/screenshots/project/e_commerce/home/home_3.png";
import home4 from "./images/screenshots/project/e_commerce/home/home_4.png";

import register1 from "./images/screenshots/project/e_commerce/register/register-shot1.png";
import register2 from "./images/screenshots/project/e_commerce/register/register-email-error.png";
import register3 from "./images/screenshots/project/e_commerce/register/register-confirm-password-error.png";
import register4 from "./images/screenshots/project/e_commerce/register/register-email-verification.png";

import login1 from "./images/screenshots/project/e_commerce/login/login_1.png";

import cart1 from "./images/screenshots/project/e_commerce/cart/cart_1.png";
import cart2 from "./images/screenshots/project/e_commerce/cart/cart_2.png";

import productSearch from "./images/screenshots/project/e_commerce/products/products_4.png";

import productDetails1 from "./images/screenshots/project/e_commerce/product-details/product_details_1.png";
import productDetails2 from "./images/screenshots/project/e_commerce/product-details/product_details_2.png";
import productDetails3 from "./images/screenshots/project/e_commerce/product-details/product_details_3.png";

import checkout1 from "./images/screenshots/project/e_commerce/checkout/checkout_1.png";
import checkout2 from "./images/screenshots/project/e_commerce/checkout/checkout_2.png";
import checkout3 from "./images/screenshots/project/e_commerce/checkout/checkout_3.png";
import checkout4 from "./images/screenshots/project/e_commerce/checkout/checkout_4.png";

import orderDetail1 from "./images/screenshots/project/e_commerce/order-details/order_details_1.png";
import orderDetail2 from "./images/screenshots/project/e_commerce/profile/profile_3.png";

import wishlist1 from "./images/screenshots/project/e_commerce/profile/profile_2.png";

import review1 from "./images/screenshots/project/e_commerce/review/review_1.png";

import responsiveHome1 from "./images/screenshots/project/e_commerce/responsive/home_1.png";
import responsiveHome2 from "./images/screenshots/project/e_commerce/responsive/home_2.png";
import responsiveHome3 from "./images/screenshots/project/e_commerce/responsive/home_3.png";
import responsiveHome4 from "./images/screenshots/project/e_commerce/responsive/home_4.png";

import responsiveProducts from "./images/screenshots/project/e_commerce/responsive/products_1.png";

import responsiveProductDetails1 from "./images/screenshots/project/e_commerce/responsive/product_details_1.png";
import responsiveProductDetails2 from "./images/screenshots/project/e_commerce/responsive/product_details_2.png";
import responsiveProductDetails3 from "./images/screenshots/project/e_commerce/responsive/product_details_3.png";

import responsiveCart1 from "./images/screenshots/project/e_commerce/responsive/cart_1.png";

import responsiveCheckout1 from "./images/screenshots/project/e_commerce/responsive/checkout_1.png";
import responsiveCheckout2 from "./images/screenshots/project/e_commerce/responsive/checkout_2.png";

import responsiveReview1 from "./images/screenshots/project/e_commerce/responsive/reviews_rating_1.png";

import responsiveGiveReview1 from "./images/screenshots/project/e_commerce/responsive/give_review_1.png";

export const chatApplicationShots = {
	loginScreenShots: {
		loginPage,
		// loginInvalidEmailError,
		loginEmailPasswordError,
	},
	registerScreenShots: {
		registerPage,
		registerInvalidEmail,
		registerEmailAlreadyUsed,
		registerPasswordConfirmPasswordNotMatch,
	},
	realTimeMessagingShots: {
		realTimeMessaging1,
		realTimeMessaging2,
		realTimeMessaging3,
	},
	voiceCall: {
		voiceCallShot1,
		voiceCallShot2,
		voiceCallShot3,
	},
	videoCall: {
		videoCallShot1,
		videoCallShot2,
		videoCallShot3,
	},
	imageSharingShots: {
		imageSelecting,
		imageSelected,
		imageSent,
	},
	fileSharingShots: {
		fileSelecting,
		fileSelected,
		fileSent,
	},
	recordingShots: {
		recordingStarted,
		recordingSaved,
		recordingSent,
		recordingPlay,
	},
	onlineStatusShots: {
		onlineStatus1,
	},
	paginationShots: {
		pagination1,
	},
	responsiveDesign: {
		homeMobile,
		homeTab,
		chatPageMobile,
		chatPageTab,
		profilePageDesktop,
		profilePageMobile1,
		profilePageMobile2,
	},
};

export const eCommerceShots = {
	ecomHomePage: {
		home1,
		home2,
		home3,
		home4,
	},
	userAuthentication: {
		register1,
		register2,
		register3,
		register4,
		login1,
	},
	shoppingCart: {
		cart1,
		cart2,
	},
	productSearch: {
		productSearch,
	},
	productDetais: {
		productDetails1,
		productDetails2,
		productDetails3,
	},
	checkoutPayment: {
		checkout1,
		checkout2,
		checkout3,
		checkout4,
	},
	orders: {
		orderDetail1,
		orderDetail2,
	},
	wishlist: {
		wishlist1,
	},
	reveiw: {
		review1,
	},
	responsiveEcom: {
		responsiveHome1,
		responsiveHome2,
		responsiveHome3,
		responsiveHome4,
		responsiveProducts,
		responsiveProductDetails1,
		responsiveProductDetails2,
		responsiveProductDetails3,
		responsiveReview1,
		responsiveGiveReview1,
		responsiveCart1,
		responsiveCheckout1,
		responsiveCheckout2,
	},
};
